import { Injectable } from '@angular/core';

import {
  mutationAddModifiers,
  mutationDeleteModifierGroup,
  mutationModifierGroupAttachProducts,
  mutationModifierGroupCreate,
  mutationModifierGroupDetachProducts,
  mutationModifierGroupUpdate,
  mutationRemoveModifiers,
  mutationUpdateModifier,
  queryModifierGroupById,
  queryModifierGroups,
  queryModifierGroupsShort,
  queryModifierGroupWhereUsedAsModifier,
} from '@api';
import { ApiService } from '@core/service';
import {
  Mutation,
  MutationAddModifiersArgs,
  MutationDeleteModifierGroupArgs,
  MutationModifierGroupAttachProductsArgs,
  MutationModifierGroupCreateArgs,
  MutationModifierGroupDetachProductsArgs,
  MutationModifierGroupUpdateArgs,
  MutationRemoveModifiersArgs,
  MutationResult,
  MutationUpdateModifierArgs,
  Query,
  QueryModifierGroupByIdArgs,
  QueryModifierGroupsArgs,
  QueryModifierGroupsArgsWithModifiers,
  QueryModifierGroupWhereUsedAsModifierArgs,
  QueryResult,
} from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ModifierGroupApi {
  constructor(private apiService: ApiService) {}

  getModifierGroupById(variables: QueryModifierGroupByIdArgs): QueryResult<'modifierGroupById'> {
    return this.apiService.query<Query<'modifierGroupById'>, QueryModifierGroupByIdArgs>({
      query: queryModifierGroupById,
      variables,
    });
  }

  getModifierGroups(variables?: QueryModifierGroupsArgsWithModifiers): QueryResult<'modifierGroups'> {
    return this.apiService.query<Query<'modifierGroups'>, QueryModifierGroupsArgs>({
      query: queryModifierGroups,
      variables,
    });
  }

  getModifierGroupsShort(variables: QueryModifierGroupsArgs): QueryResult<'modifierGroups'> {
    return this.apiService.query<Query<'modifierGroups'>, QueryModifierGroupsArgs>({
      query: queryModifierGroupsShort,
      variables,
    });
  }

  getWhereUsedAsModifier(variables: QueryModifierGroupWhereUsedAsModifierArgs): QueryResult<'modifierGroupWhereUsedAsModifier'> {
    return this.apiService.query<Query<'modifierGroupWhereUsedAsModifier'>, QueryModifierGroupWhereUsedAsModifierArgs>({
      query: queryModifierGroupWhereUsedAsModifier,
      variables,
    });
  }

  createModifierGroup(variables: MutationModifierGroupCreateArgs): MutationResult<'modifierGroupCreate'> {
    return this.apiService.mutate<Mutation<'modifierGroupCreate'>, MutationModifierGroupCreateArgs>({
      mutation: mutationModifierGroupCreate,
      variables,
    });
  }

  updateModifierGroup(variables: MutationModifierGroupUpdateArgs): MutationResult<'modifierGroupUpdate'> {
    return this.apiService.mutate<Mutation<'modifierGroupUpdate'>, MutationModifierGroupUpdateArgs>({
      mutation: mutationModifierGroupUpdate,
      variables,
    });
  }

  deleteModifierGroup(variables: MutationDeleteModifierGroupArgs): MutationResult<'deleteModifierGroup'> {
    return this.apiService.mutate<Mutation<'deleteModifierGroup'>, MutationDeleteModifierGroupArgs>({
      mutation: mutationDeleteModifierGroup,
      variables,
    });
  }

  attachProductsToModifierGroup(variables: MutationModifierGroupAttachProductsArgs): MutationResult<'modifierGroupAttachProducts'> {
    return this.apiService.mutate<Mutation<'modifierGroupAttachProducts'>, MutationModifierGroupAttachProductsArgs>({
      mutation: mutationModifierGroupAttachProducts,
      variables,
    });
  }

  detachProductsFromModifierGroup(variables: MutationModifierGroupDetachProductsArgs): MutationResult<'modifierGroupDetachProducts'> {
    return this.apiService.mutate<Mutation<'modifierGroupDetachProducts'>, MutationModifierGroupDetachProductsArgs>({
      mutation: mutationModifierGroupDetachProducts,
      variables,
    });
  }

  addModifiers(variables: MutationAddModifiersArgs): MutationResult<'addModifiers'> {
    return this.apiService.mutate<Mutation<'addModifiers'>, MutationAddModifiersArgs>({
      mutation: mutationAddModifiers,
      variables,
    });
  }

  updateModifier(variables: MutationUpdateModifierArgs): MutationResult<'updateModifier'> {
    return this.apiService.mutate<Mutation<'updateModifier'>, MutationUpdateModifierArgs>({
      mutation: mutationUpdateModifier,
      variables,
    });
  }

  removeModifiers(variables: MutationRemoveModifiersArgs): MutationResult<'removeModifiers'> {
    return this.apiService.mutate<Mutation<'removeModifiers'>, MutationRemoveModifiersArgs>({
      mutation: mutationRemoveModifiers,
      variables,
    });
  }
}
