import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputImageComponent implements OnChanges {
  @Input() label: string = '';
  @Input() fileUrl: SafeUrl | string | null = null;
  @Input() fileName: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() testId: string;
  @Input() isCover: boolean = false;

  @Output() removeFile = new EventEmitter<void>();
  @Output() previewLoad = new EventEmitter<void>();
  @Output() previewError = new EventEmitter<ErrorEvent>();

  @ViewChild('image') image: ElementRef<HTMLImageElement>;

  constructor(private sanitizer: DomSanitizer) {}

  iconSrc: SVG_ICONS_TYPE | null = null;
  imageSrc: SafeUrl | null = null;
  previewLoading: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('fileUrl' in changes) {
      if (changes.fileUrl.currentValue) {
        this.showFilePreview();
      } else {
        this.removeFilePreview();
      }
    }
  }

  get defaultLabel(): string {
    if (this.loading) {
      return 'Загрузка...';
    }

    if (this.fileUrl) {
      return this.fileName;
    }

    return 'Выберите файл';
  }

  get showControl(): boolean {
    return !this.disabled && (!!this.fileUrl || this.loading);
  }

  showFilePreview() {
    this.previewLoading = true;
    this.imageSrc = typeof this.fileUrl === 'string' ? this.sanitizer.bypassSecurityTrustUrl(this.fileUrl.toString()) : this.fileUrl;
    this.iconSrc = null;
  }

  removeFilePreview() {
    this.iconSrc = null;
    this.imageSrc = null;
    this.previewLoading = false;
  }

  handleLoadFinished() {
    this.previewLoading = false;

    this.previewLoad.emit();
  }

  handleLoadError(e: ErrorEvent) {
    this.previewLoading = false;
    this.imageSrc = null;
    this.iconSrc = 'description';

    this.previewError.emit(e);
  }

  handleRemove(e: Event) {
    e.stopPropagation();

    this.removeFile.emit();
  }

  get inputImageClasses(): string[] {
    const classes = ['nm-input-image'];

    if (this.disabled) {
      classes.push('nm-input-image-disabled');
    }

    if (this.imageSrc) {
      classes.push('nm-input-image-preview');
    }

    return classes;
  }

  get controlIconClasses(): string[] {
    const classes = ['control-icon'];

    if (this.fileUrl !== null) {
      classes.push('trash-icon');
    }

    return classes;
  }
}
