export enum AnalyticsRoute {
  abcReport = 'abc-report',
  productsReport = 'products-report',
  categoriesReport = 'categories-report',
  dashboardReport = 'dashboard-report',
  dashboardClientReport = 'dashboard-client-report',
  dashboardSurchargeReport = 'dashboard-surcharge-report',
}

export enum AnalyticsRouteName {
  abcReport = 'ABC-отчет',
  productsReport = 'Отчет по товарам',
  categoriesReport = 'Отчет по категориям',
  dashboardReport = 'Обзор организации',
  dashboardClientReport = 'Обзор клиентов ᵝ',
  dashboardSurchargeReport = 'Отчет по наценкам',
  shifts = 'Кассовые смены',
}

export enum AnalyticsRouteParam {
  productId = 'idProduct',
}

export const ANALYTICS_ITEM_ABC = {
  title: AnalyticsRouteName.abcReport,
  route: AnalyticsRoute.abcReport,
};

export const ANALYTICS_ITEM_PRODUCTS = {
  title: AnalyticsRouteName.productsReport,
  route: AnalyticsRoute.productsReport,
  routes: [
    {
      title: '',
      route: AnalyticsRouteParam.productId,
      keepUrlParams: true,
    },
  ],
};

export const ANALYTICS_ITEM_CATEGORIES = {
  title: AnalyticsRouteName.categoriesReport,
  route: AnalyticsRoute.categoriesReport,
};

export const ANALYTICS_ITEM_DASHBOARD = {
  title: AnalyticsRouteName.dashboardReport,
  route: AnalyticsRoute.dashboardReport,
};

export const ANALYTICS_ITEM_DASHBOARD_CLIENT = {
  title: AnalyticsRouteName.dashboardClientReport,
  route: AnalyticsRoute.dashboardClientReport,
};

export const ANALYTICS_ITEM_DASHBOARD_SURCHARGE = {
  title: AnalyticsRouteName.dashboardSurchargeReport,
  route: AnalyticsRoute.dashboardSurchargeReport,
};

export const ANALYTICS_CHILD_ITEMS = [
  ANALYTICS_ITEM_DASHBOARD,
  ANALYTICS_ITEM_DASHBOARD_CLIENT,
  ANALYTICS_ITEM_DASHBOARD_SURCHARGE,
  ANALYTICS_ITEM_ABC,
  ANALYTICS_ITEM_PRODUCTS,
  ANALYTICS_ITEM_CATEGORIES,
];
