import { gql } from 'apollo-angular';

export const fragmentOrdersReport = gql`
  fragment OrdersReport on OrdersReport {
    orderId
    currency
    orderNumber
    date
    closeDate
    itemsCount
    guestsCount
    paySystemNames
    orderAmount
    paymentsAmount
    taxAmount
    incomeAmount
    fiscalStatus
    storeName
    storeId
    status
    shift
    type
    customerInfo
    customerId
    creditedPoints
    operator {
      id
      firstName
      middleName
      lastName
    }
  }
`;

export const fragmentOrdersReportPage = gql`
  fragment OrdersReportPage on OrdersReportPage {
    content {
      ...OrdersReport
    }
    total
    totalPages
    pageNumber
  }
  ${fragmentOrdersReport}
`;

export const fragmentOrdersReportTotal = gql`
  fragment OrdersReportTotal on OrdersReportTotal {
    itemsCount
    guestsCount
    ordersAmount
    paymentsAmount
    taxAmount
    incomeAmount
    ordersCount
  }
`;

export const fragmentOrdersReportOutput = gql`
  fragment OrdersReportOutput on OrdersReportOutput {
    rows(pageRequest: $pageRequestInput, sort: $sort) {
      ...OrdersReportPage
    }
    total {
      ...OrdersReportTotal
    }
  }
  ${fragmentOrdersReportPage}
  ${fragmentOrdersReportTotal}
`;

export const queryOrdersReport = gql`
  query ordersReport($input: OrdersReportInput!, $pageRequestInput: PageRequestInput, $sort: [OrdersReportSort!]) {
    ordersReport(input: $input) {
      ...OrdersReportOutput
    }
  }
  ${fragmentOrdersReportOutput}
`;

export const queryOrdersReportTimeSeries = gql`
  query ordersReportTimeSeries(
    $reportInput: OrdersReportInput!
    $timeSeriesInput: TimeSeriesInput!
    $revenue: Boolean!
    $profit: Boolean!
    $itemsCount: Boolean!
    $guestsCount: Boolean!
    $ordersCount: Boolean!
  ) {
    ordersReportTimeSeries(reportInput: $reportInput, timeSeriesInput: $timeSeriesInput) {
      elements {
        revenue @include(if: $revenue)
        profit @include(if: $profit)
        itemsCount @include(if: $itemsCount)
        guestsCount @include(if: $guestsCount)
        ordersCount @include(if: $ordersCount)
        timestamp
      }
    }
  }
`;
