<div class="modal">
  <ng-container *ngIf="hasError">
    <div class="header">
      <div class="alert"></div>

      <span class="title">Ошибка</span>

      <span>Приглашение не действительно. Попробуйте обратиться к сотруднику, пригласившему вас в организацию.</span>
    </div>

    <lib-my-button
      class="button"
      buttonSize="L"
      buttonTitle="Закрыть"
      buttonTheme="outline"
      [isFullWidth]="true"
      (clickButton)="close()"
    ></lib-my-button>
  </ng-container>

  <ng-container *ngIf="!hasError && organization">
    <div class="header">
      <div class="icon"></div>

      <div class="title">
        Вас пригласили в <span *ngIf="totalOrganizations > 0">новую </span> организацию
        <ng-container *ngIf="organization?.name">
          <br /><span>«{{ organization?.name }}»</span>
        </ng-container>
        <ng-container *ngIf="organization?.shortId">
          <br /><span class="small">(ID: {{ organization?.shortId }})</span>
        </ng-container>
      </div>

      <ng-container *ngIf="isInviteAdminRole">C ролью «{{ employeeRoles[0].role }}» во все точки продаж</ng-container>

      <ng-container *ngIf="!isInviteAdminRole">
        <ng-container *ngFor="let employeeRole of employeeRoles">
          <div class="role">C ролью «{{ employeeRole.role }}» в точку продаж «{{ employeeRole.storeName }}»</div>
        </ng-container>
      </ng-container>
    </div>

    <div class="buttons">
      <lib-my-button
        *ngIf="totalOrganizations >= 1"
        class="button"
        buttonSize="L"
        buttonTitle="Отклонить"
        buttonTheme="outline"
        [isFullWidth]="true"
        (clickButton)="declineInvitation()"
      ></lib-my-button>

      <lib-my-button
        class="button"
        buttonSize="L"
        buttonTitle="Продолжить"
        buttonTheme="primary"
        [isFullWidth]="true"
        (clickButton)="acceptInvitation()"
      ></lib-my-button>

      <div class="checkbox" *ngIf="totalOrganizations > 0" #wrapper>
        <nm-checkbox (checkedChange)="checkRedirectOnAccept()" [ngModel]="isRedirect">
          <span class="label">и перейти в организацию</span>
        </nm-checkbox>
      </div>
    </div>
  </ng-container>
</div>
