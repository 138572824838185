import { gql } from 'apollo-angular';

export const fragmentConsolidatedReport = gql`
  fragment ConsolidatedReport on ConsolidatedReport {
    averageCheck
    discount
    ordersCount
    profit
    revenue
    timestamp
  }
`;

export const fragmentConsolidatedReportPage = gql`
  fragment ConsolidatedReportPage on ConsolidatedReportPage {
    content {
      ...ConsolidatedReport
    }
    totalPages
    total
    pageNumber
  }
  ${fragmentConsolidatedReport}
`;

export const fragmentConsolidatedReportTotal = gql`
  fragment ConsolidatedReportTotal on ConsolidatedReportTotal {
    averageCheck
    discount
    ordersCount
    profit
    revenue
    guestCount
    averageItemsCount
    discount
    foodCost
    averageDuration
  }
`;

export const fragmentConsolidatedReportOutput = gql`
  fragment ConsolidatedReportOutput on ConsolidatedReportOutput {
    rows(pageRequest: $pageRequestInput, sort: $sort) {
      ...ConsolidatedReportPage
    }
    total {
      ...ConsolidatedReportTotal
    }
  }
  ${fragmentConsolidatedReportPage}
  ${fragmentConsolidatedReportTotal}
`;

export const fragmentConsolidatedReportOutputShort = gql`
  fragment ConsolidatedReportOutput on ConsolidatedReportOutput {
    total {
      ...ConsolidatedReportTotal
    }
  }
  ${fragmentConsolidatedReportTotal}
`;

export const queryConsolidatedReport = gql`
  query consolidatedReport($input: ConsolidatedReportInput!, $pageRequestInput: PageRequestInput, $sort: [ConsolidatedReportSort!]) {
    consolidatedReport(input: $input) {
      ...ConsolidatedReportOutput
    }
  }
  ${fragmentConsolidatedReportOutput}
`;

export const queryConsolidatedReportShort = gql`
  query consolidatedReport($input: ConsolidatedReportInput!) {
    consolidatedReport(input: $input) {
      ...ConsolidatedReportOutput
    }
  }
  ${fragmentConsolidatedReportOutputShort}
`;

export const queryConsolidatedReportTimeSeries = gql`
  query consolidatedReportTimeSeries(
    $input: ConsolidatedReportInput!
    $timeSeriesInput: TimeSeriesInput!
    $revenue: Boolean!
    $profit: Boolean!
    $ordersCount: Boolean!
    $guestCount: Boolean!
  ) {
    consolidatedReportTimeSeries(input: $input, timeSeriesInput: $timeSeriesInput) {
      elements {
        revenue @include(if: $revenue)
        profit @include(if: $profit)
        guestCount @include(if: $guestCount)
        ordersCount @include(if: $ordersCount)
        timestamp
      }
    }
  }
`;

export const queryConsolidatedReportXls = gql`
  query consolidatedReportXls($input: ConsolidatedReportInput!, $sort: [ConsolidatedReportSort!]) {
    consolidatedReportXls(input: $input, sort: $sort) {
      url
    }
  }
`;
