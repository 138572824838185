import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

//CUSTOMER
export const fragmentCustomerGroupPage = gql`
  fragment CustomerGroupPage on CustomerGroupPage {
    content {
      id
      color
      name
      description
      customersCount
      automatic
    }
    ...Page
  }
  ${fragmentPage}
`;

export const fragmentCustomerResult = gql`
  fragment CustomerResult on CustomerResult {
    output {
      id
      firstName
      lastName
      phoneNumber
      cardNumber
      groups {
        ...CustomerGroupPage
      }
    }
    errorMessage
    result
  }
  ${fragmentCustomerGroupPage}
`;

export const mutationCreateCustomer = gql`
  mutation createCustomer($customer: CustomerCreateInput!) {
    createCustomer(customer: $customer) {
      ...CustomerResult
    }
  }
  ${fragmentCustomerResult}
`;

export const mutationUpdateCustomer = gql`
  mutation updateCustomer($customer: CustomerUpdateInput!) {
    updateCustomer(customer: $customer) {
      ...CustomerResult
    }
  }
  ${fragmentCustomerResult}
`;

export const mutationUpdateCustomerV2 = gql`
  mutation updateCustomerV2($customer: CustomerUpdateInputV2!) {
    updateCustomerV2(customer: $customer) {
      ...CustomerResult
    }
  }
  ${fragmentCustomerResult}
`;

export const mutationDeleteCustomer = gql`
  mutation deleteCustomer($id: UUID!) {
    deleteCustomer(id: $id) {
      ...CustomerResult
    }
  }
  ${fragmentCustomerResult}
`;

// CUSTOMER GROUP
export const fragmentCustomerGroupResult = gql`
  fragment CustomerGroupResult on CustomerGroupResult {
    output {
      id
      name
    }
    errorMessage
    result
  }
`;

export const queryCustomer = gql`
  query customer($customerId: UUID!) {
    customer(customerId: $customerId) {
      id
      firstName
      lastName
      phoneNumber
      cardNumber
      dateOfBirth
      comment
      address {
        baseAddress
        door
        doorCode
        flatNumber
        floorString
      }
      groups {
        ...CustomerGroupPage
      }
      points
      gender
      age
    }
  }
  ${fragmentCustomerGroupPage}
`;

export const fragmentCustomerPage = gql`
  fragment CustomerPage on CustomerPage {
    content {
      id
      firstName
      lastName
      phoneNumber
      cardNumber
      points
      groups {
        ...CustomerGroupPage
      }
    }
    ...Page
  }
  ${fragmentPage}
  ${fragmentCustomerGroupPage}
`;

export const fragmentShortCustomerPage = gql`
  fragment CustomerPage on CustomerPage {
    content {
      id
      firstName
      lastName
      phoneNumber
      cardNumber
    }
    ...Page
  }
  ${fragmentPage}
`;

export const queryCustomers = gql`
  query customers($filter: CustomerFilterInput, $pageRequest: PageRequestInput) {
    customers(filter: $filter, pageRequest: $pageRequest) {
      ...CustomerPage
    }
  }
  ${fragmentCustomerPage}
`;

export const queryShortCustomers = gql`
  query customers($filter: CustomerFilterInput, $pageRequest: PageRequestInput) {
    customers(filter: $filter, pageRequest: $pageRequest) {
      ...CustomerPage
    }
  }
  ${fragmentShortCustomerPage}
`;
export const mutationCreateCustomerGroup = gql`
  mutation createCustomerGroup($customerGroup: CustomerGroupCreateInput!) {
    createCustomerGroup(customerGroup: $customerGroup) {
      ...CustomerGroupResult
    }
  }
  ${fragmentCustomerGroupResult}
`;

export const mutationUpdateCustomerGroup = gql`
  mutation updateCustomerGroup($customerGroup: CustomerGroupUpdateInput!) {
    updateCustomerGroup(customerGroup: $customerGroup) {
      ...CustomerGroupResult
    }
  }
  ${fragmentCustomerGroupResult}
`;

export const mutationCreateCustomerGroupV2 = gql`
  mutation createCustomerGroupV2($group: GroupCreateInput!) {
    createCustomerGroupV2(group: $group) {
      group {
        ... on ManualCustomerGroup {
          id
          color
          name
          description
          customersCount
        }
        ... on AutomaticCustomerGroup {
          id
          color
          name
          description
          customersCount
          rule {
            period {
              dateFrom
              dateTo
              validFor
            }
            storeId
            sumFrom
            sumTo
            type
          }
        }
      }
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationUpdateCustomerGroupV2 = gql`
  mutation updateCustomerGroupV2($group: GroupUpdateInput!) {
    updateCustomerGroupV2(group: $group) {
      group {
        ... on ManualCustomerGroup {
          id
          color
          name
          description
          customersCount
        }
        ... on AutomaticCustomerGroup {
          id
          color
          name
          description
          customersCount
          rule {
            period {
              dateFrom
              dateTo
              validFor
            }
            storeId
            sumFrom
            sumTo
            type
          }
        }
      }
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationDeleteCustomerGroup = gql`
  mutation deleteCustomerGroup($id: UUID!) {
    deleteCustomerGroup(id: $id) {
      ...CustomerGroupResult
    }
  }
  ${fragmentCustomerGroupResult}
`;

export const queryCustomerGroup = gql`
  query customerGroup($customerGroupId: UUID!) {
    customerGroup(customerGroupId: $customerGroupId) {
      id
      name
      description
      color
    }
  }
`;

export const queryCustomerGroups = gql`
  query customerGroups($filter: CustomerGroupFilterInput, $pageRequest: PageRequestInput, $sort: [CustomerGroupsSort!]) {
    customerGroups(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      ...CustomerGroupPage
    }
  }
  ${fragmentCustomerGroupPage}
`;

// LOYALTY GROUP

export const queryLoyaltyGroup = gql`
  query loyaltyGroup($customerGroupId: UUID!) {
    loyaltyGroup(customerGroupId: $customerGroupId) {
      ... on ManualCustomerGroup {
        id
        color
        name
        description
        customersCount
      }
      ... on AutomaticCustomerGroup {
        id
        color
        name
        description
        customersCount
        rule {
          period {
            dateFrom
            dateTo
            validFor
          }
          storeId
          sumFrom
          sumTo
          type
        }
      }
    }
  }
`;

export const fragmentLoyaltyGroupPage = gql`
  fragment LoyaltyGroupPage on LoyaltyGroupPage {
    content {
      ... on ManualCustomerGroup {
        id
        color
        name
        description
        customersCount
      }
      ... on AutomaticCustomerGroup {
        id
        color
        name
        description
        customersCount
      }
    }
    ...Page
  }
  ${fragmentPage}
`;

export const queryLoyaltyGroups = gql`
  query loyaltyGroups($filter: CustomerGroupFilterInput, $pageRequest: PageRequestInput, $sort: [CustomerGroupsSort!]) {
    loyaltyGroups(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      ...LoyaltyGroupPage
    }
  }
  ${fragmentLoyaltyGroupPage}
`;

export const mutationMakePointAccountTransaction = gql`
  mutation makePointAccountTransaction($input: MakePointAccountTransactionInput!) {
    makePointAccountTransaction(input: $input) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const queryCustomerOrders = gql`
  query customerOrders($input: CustomerOrdersInput!, $pageRequest: PageRequestInput) {
    customerOrders(input: $input, pageRequest: $pageRequest) {
      content {
        discountAmount
        itemsCount
        orderAmount
        orderId
        orderNumber
        paySystemNames
        paymentDate
        revenue
        shift
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const queryOrderWidget = gql`
  query orderWidget($widgetInput: OrderWidgetInput!) {
    orderWidget(widgetInput: $widgetInput) {
      widget {
        averageCheck
        itemsCount
        orderAmount
        orderCount
        revenue
      }
    }
  }
`;

export const queryCustomerForCurrentDayWidget = gql`
  query customerForCurrentDayWidget($widgetInput: CustomerForCurrentDayWidgetInput!) {
    customerForCurrentDayWidget(widgetInput: $widgetInput) {
      widget {
        averageCheck
        orderAmount
        orderCount
        points
        since
      }
    }
  }
`;

export const queryPointTransactions = gql`
  query pointAccountTransactions($filter: PointTransactionFilterInput, $pageRequest: PageRequestInput!) {
    pointAccountTransactions(filter: $filter, pageRequest: $pageRequest) {
      pageNumber
      pageSize
      total
      totalPages
      content {
        ... on PointAccountTransactionOrder {
          after
          amount
          before
          customerId
          date
          operator {
            firstName
            id
            lastName
            middleName
            roles
          }
          status
          storeId
          terminalId
          type
          order {
            id
            amount
            orderNumber
          }
        }
        ... on PointAccountTransactionManual {
          after
          amount
          before
          comment
          customerId
          date
          operator {
            id
            firstName
            lastName
            middleName
            roles
          }
          status
          type
        }
      }
    }
  }
`;
