import { gql } from 'apollo-angular';

export const queryOrderByIdShort = gql`
  query orderById($orderId: UUID!) {
    orderById(orderId: $orderId) {
      id
      orderNumber
      orderStatus
      dateLastPayedDate
      payments {
        amount {
          amountValue
          currencyUnit
        }
        paySystem {
          type
        }
      }
      items {
        name
        quantity {
          quantity
          unit
        }
        total {
          result {
            amountValue
            currencyUnit
          }
        }
        appliedDiscounts {
          percent
        }
        appliedModifiers {
          ... on StockUnitAppliedModifier {
            id
            name
            resultAmount {
              amountValue
              currencyUnit
            }
            stockUnitId
          }
          ... on TextAppliedModifier {
            id
            name
          }
        }
      }
      discountAmount {
        amountValue
        currencyUnit
      }
      totalAmount {
        amountValue
        currencyUnit
      }
      amount {
        amountValue
        currencyUnit
      }
    }
  }
`;

export const queryOrderByIdReport = gql`
  query orderById($orderId: UUID!) {
    orderById(orderId: $orderId) {
      id
      orderNumber
      lastModifiedDate
      createdDate
      closedDate
      orderStatus
      payments {
        id
        paySystem {
          id
          name
        }
        amount {
          amountValue
        }
      }
      shiftId
      terminal {
        id
        name
      }
      owner {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        phoneNumber
      }
      operator {
        id
        firstName
        middleName
        lastName
      }
      loyaltyPoints {
        earn
        spent
        spentAmount
      }
    }
  }
`;

export const queryOrderByIdCompound = gql`
  query orderById($orderId: UUID!) {
    orderById(orderId: $orderId) {
      id
      discountAmount {
        amountValue
      }
      itemDiscount {
        amountValue
      }
      orderDiscount {
        amountValue
      }
      amount {
        amountValue
      }
      totalAmount {
        amountValue
      }
      items {
        id
        name
        positionTypeCode
        quantity {
          quantity
          unit
        }
        total {
          amount {
            amountValue
          }
          result {
            amountValue
          }
          discount {
            amountValue
          }
        }
        appliedDiscounts {
          percent
        }
        appliedModifiers {
          ... on StockUnitAppliedModifier {
            id
            name
            resultAmount {
              amountValue
            }
            quantity {
              quantity
              unit
            }
          }
          ... on TextAppliedModifier {
            id
            name
          }
        }
      }
    }
  }
`;

export const queryLeavingByOrderDocumentV2 = gql`
  query leavingByOrderDocumentV2($filter: LeavingByOrderDocumentV2FilterInput!) {
    leavingByOrderDocumentV2(filter: $filter) {
      id
      docNumber
      documentItems {
        id
        name
        factQuantity
        stockUnit {
          id
          unit
          primePrice {
            amountValue
            currencyUnit
          }
        }
        resultLeavingItem {
          id
          name
          quantity
          unit
          primePrice
          currency
          coreItem
        }
      }
    }
  }
`;
