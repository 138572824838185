import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { queryCatalogByOrganization } from '@api';
import { CatalogStorage } from '@app/modules/catalog/services/catalog/catalog.storage';
import { CatalogRoute, RootNavigationRoute } from '@constants';
import { ApiService } from '@core/service';
import { SessionStorage } from '@services/api';
import { Catalog, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  #catalog = new BehaviorSubject<Catalog | null>(null);
  catalog$ = this.#catalog.asObservable();

  constructor(
    private router: Router,
    private sessionStorage: SessionStorage,
    private api: ApiService,
    private catalogStorage: CatalogStorage,
  ) {}

  isAccess(catalog: Catalog): boolean {
    const { positionType, snoValue, vatValue } = catalog ?? {};

    return Boolean(positionType && positionType.code && snoValue && snoValue.sno && vatValue && vatValue.vat);
  }
  openSettingsPage() {
    return this.router.navigate([this.sessionStorage.getOrgId(), RootNavigationRoute.catalog, CatalogRoute.settings]);
  }

  openWorkShopsPage() {
    return this.router.navigate([this.sessionStorage.getOrgId(), RootNavigationRoute.catalog, CatalogRoute.workshops]);
  }

  getCatalogByOrganization(): QueryResult<'catalogByOrganization'> {
    return this.api.query<Query<'catalogByOrganization'>, {}>({
      query: queryCatalogByOrganization,
      fetchPolicy: 'no-cache',
    });
  }

  fetchCatalog() {
    const subscribe = this.catalogStorage.fetchCatalogByOrganization();

    subscribe.subscribe((res) => {
      const catalog = res.data.catalogByOrganization;

      if (catalog) {
        this.updateCatalog(catalog);
      }
    });

    return subscribe;
  }

  updateCatalog(catalog: Catalog): void {
    this.#catalog.next(catalog);
  }
}
