import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuApi } from '@services/catalog';
import { NotifyService } from '@services/shared';
import {
  CoreSchema,
  Menu,
  MenuRc,
  MutationAddMenuRcArgs,
  MutationAddMenuStructureArgs,
  MutationChangeItemRcPositionArgs,
  MutationChangeMenuSectionPositionArgs,
  MutationChangeSectionRcPositionArgs,
  MutationCreateItemsRcListArgs,
  MutationCreateMenuArgs,
  MutationCreateMenuItemArgs,
  MutationCreateMenuRcArgs,
  MutationCreateMenuSectionArgs,
  MutationCreateMenuStructureArgs,
  MutationCreateSectionRcArgs,
  MutationDeleteItemRcArgs,
  MutationDeleteMenuArgs,
  MutationDeleteMenuItemArgs,
  MutationDeleteMenuRcArgs,
  MutationDeleteMenuSectionArgs,
  MutationDeleteSectionRcArgs,
  MutationDuplicateMenuArgs,
  MutationDuplicateMenuRcArgs,
  MutationMoveElementsArgs,
  MutationOnlineMenuSectionSetImageArgs,
  MutationOnlineMenuSetActiveStateArgs,
  MutationResult,
  MutationUpdateItemRcArgs,
  MutationUpdateMenuArgs,
  MutationUpdateMenuItemArgs,
  MutationUpdateMenuItemsListArgs,
  MutationUpdateMenuRcArgs,
  MutationUpdateMenuSectionArgs,
  MutationUpdateMenuSectionsListArgs,
  MutationUpdateMenuSectionV2PatchArgs,
  MutationUpdateSectionRcArgs,
  QueryAllCatalogProductsPageableArgs,
  QueryAllMenuItemsBySectionArgs,
  QueryAllMenusArgs,
  QueryAllMenusByStoreIdsPageableArgs,
  QueryAllRcMenusByStoreIdsPageableArgs,
  QueryFindMenuElementsArgs,
  QueryMenuArgs,
  QueryMenuItemArgs,
  QueryMenuRcArgs,
  QueryMenuRcElementArgs,
  QueryMenuRcElementsArgs,
  QueryMenuRcImmediateDescendantsArgs,
  QueryMenuRcRootElementsArgs,
  QueryMenuSectionArgs,
  QueryMenuSectionsBySectionArgs,
  QueryResult,
  QueryStockUnitsArgs,
  Store,
} from '@typings';

import { ProductApi } from '../product/product.api';

@Injectable({
  providedIn: 'root',
})
export class MenuStorage {
  #stores = new BehaviorSubject<Store[]>([]);
  stores$ = this.#stores.asObservable();

  #menu = new BehaviorSubject<MenuRc | null>(null);

  #menus = new BehaviorSubject<MenuRc[] | Menu[]>([]);
  menus$ = this.#menus.asObservable();

  constructor(private notifyService: NotifyService, private menuApi: MenuApi, private productApi: ProductApi) {}

  setMenu(menu: MenuRc) {
    this.#menu.next(menu);
  }

  get menu() {
    return this.#menu.getValue();
  }
  addMenuRcStructure(variables: MutationAddMenuRcArgs): MutationResult<'addMenuRc'> {
    return this.menuApi.addMenuRcStructure(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении элементов',
      }),
    );
  }
  getAllMenusRc(variables: QueryAllRcMenusByStoreIdsPageableArgs): QueryResult<'menusRc'> {
    return this.menuApi.getAllMenusRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка меню',
      }),
    );
  }
  getMenuRc(variables: QueryMenuRcArgs): QueryResult<'menuRc'> {
    return this.menuApi.getMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении меню',
      }),
    );
  }
  getAllInMenuPageable(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.menuApi.getAllInMenuPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getMenuSectionsShort(variables: QueryMenuRcElementsArgs): QueryResult<'menuRcElements'> {
    return this.menuApi.getMenuSectionsShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }
  createRcMenu(variables: MutationCreateMenuRcArgs): MutationResult<'createMenuRc'> {
    return this.menuApi.createRcMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании меню',
      }),
    );
  }
  deleteMenuRc(variables: MutationDeleteMenuRcArgs): MutationResult<'deleteMenuRc'> {
    return this.menuApi.deleteMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении меню',
      }),
    );
  }
  createMenuSectionRc(variables: MutationCreateSectionRcArgs): MutationResult<'createSectionRc'> {
    return this.menuApi.createMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании категории меню',
      }),
    );
  }

  updateMenuSectionRc(variables: MutationUpdateSectionRcArgs): MutationResult<'updateSectionRc'> {
    return this.menuApi.updateMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении категории меню',
      }),
    );
  }

  deleteMenuSectionRc(variables: MutationDeleteSectionRcArgs): MutationResult<'deleteSectionRc'> {
    return this.menuApi.deleteMenuSectionRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалени категории меню',
      }),
    );
  }
  //check
  changeMenuSectionRcPosition(variables: MutationChangeSectionRcPositionArgs): MutationResult<'changeSectionRcPosition'> {
    return this.menuApi.changeMenuSectionRcPosition(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении позиции элемента меню',
      }),
    );
  }
  //check
  createMenuItemsRcList(variables: MutationCreateItemsRcListArgs): MutationResult<'createItemsRcList'> {
    return this.menuApi.createMenuItemsRcList(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении позиции элемента меню',
      }),
    );
  }
  //add
  updateMenuItemRc(variables: MutationUpdateItemRcArgs): MutationResult<'updateItemRc'> {
    return this.menuApi.updateMenuItemRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении элемента меню',
      }),
    );
  }

  deleteMenuItemRc(variables: MutationDeleteItemRcArgs): MutationResult<'deleteItemRc'> {
    return this.menuApi.deleteMenuItemRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении позиции меню',
      }),
    );
  }

  changeMenuItemRcPosition(variables: MutationChangeItemRcPositionArgs): MutationResult<'changeItemRcPosition'> {
    return this.menuApi.changeMenuItemRcPosition(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при изменении позиции элемента меню',
      }),
    );
  }

  duplicateMenuRc(variables: MutationDuplicateMenuRcArgs): MutationResult<'duplicateMenuRc'> {
    return this.menuApi.duplicateMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании меню',
      }),
    );
  }

  setStores(stores: Store[]): void {
    this.#stores.next(stores);
  }

  getStores(): Store[] {
    return this.#stores.getValue();
  }

  setMenus(menus: MenuRc[] | Menu[]): void {
    this.#menus.next(menus);
  }

  getMenus(): MenuRc[] | Menu[] {
    return this.#menus.getValue();
  }

  getAllMenus(variables: QueryAllMenusByStoreIdsPageableArgs): QueryResult<'allMenusByStoreIdsPageable'> {
    return this.menuApi.getAllMenus(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка меню',
      }),
    );
  }

  getAllMenusByStore(variables: QueryAllMenusArgs): QueryResult<'allMenus'> {
    return this.menuApi.getAllMenusByStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка меню',
      }),
    );
  }

  getMenu(variables: QueryMenuArgs): QueryResult<'menu'> {
    return this.menuApi.getMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении меню',
      }),
    );
  }

  getAllInMenu(variables: QueryFindMenuElementsArgs): QueryResult<'findMenuElements'> {
    return this.menuApi.getAllInMenu(variables);
  }

  createMenu(variables: MutationCreateMenuArgs): MutationResult<'createMenu'> {
    return this.menuApi.createMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании меню',
      }),
    );
  }
  createMenuWithStructure(variables: MutationCreateMenuStructureArgs): MutationResult<'createMenuStructure'> {
    return this.menuApi.createMenuWithStructure(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании меню',
      }),
    );
  }

  addMenuStructure(variables: MutationAddMenuStructureArgs): MutationResult<'addMenuStructure'> {
    return this.menuApi.addMenuStructure(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении элементов',
      }),
    );
  }

  deleteMenu(variables: MutationDeleteMenuArgs): MutationResult<'deleteMenu'> {
    return this.menuApi.deleteMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении меню',
        success: 'Меню успешно удалено',
      }),
    );
  }

  updateMenu(variables: MutationUpdateMenuArgs): MutationResult<'updateMenu'> {
    return this.menuApi.updateMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении меню',
      }),
    );
  }

  updateMenuRc(variables: MutationUpdateMenuRcArgs): MutationResult<'updateMenuRc'> {
    return this.menuApi.updateMenuRc(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении меню',
      }),
    );
  }

  duplicateMenu(variables: MutationDuplicateMenuArgs): MutationResult<'duplicateMenu'> {
    return this.menuApi.duplicateMenu(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при дублировании меню',
      }),
    );
  }
  moveMenuElements(variables: MutationMoveElementsArgs): MutationResult<'moveElements'> {
    return this.menuApi.moveMenuElements(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при перемещении элементов меню',
      }),
    );
  }
  getMenuElementShort(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.menuApi.getMenuElementShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элемента меню',
      }),
    );
  }

  getMenuElement(variables: QueryMenuRcElementArgs): QueryResult<'menuRcElement'> {
    return this.menuApi.getMenuElement(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элемента меню',
      }),
    );
  }

  getMenuRcRootElements(variables: QueryMenuRcRootElementsArgs): QueryResult<'menuRcRootElements'> {
    return this.menuApi.getMenuRcRootElements(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getMenuRcImmediateDescendants(variables: QueryMenuRcImmediateDescendantsArgs): QueryResult<'menuRcImmediateDescendants'> {
    return this.menuApi.getMenuRcImmediateDescendants(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  getPrimePriceAndImageForMenuItem(variables?: QueryStockUnitsArgs): QueryResult<'stockUnits'> {
    return this.menuApi.getPrimePriceAndImageForMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении элементов меню',
      }),
    );
  }

  createMenuSection(variables: MutationCreateMenuSectionArgs): MutationResult<'createMenuSection'> {
    return this.menuApi.createMenuSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании категории меню',
      }),
    );
  }

  createMenuItem(variables: MutationCreateMenuItemArgs): MutationResult<'createMenuItem'> {
    return this.menuApi.createMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании позиции меню',
      }),
    );
  }

  createMenuItemsList(variables: CoreSchema.MutationCreateMenuItemsListArgs): MutationResult<'createMenuItemsList'> {
    return this.menuApi.createMenuItemsList(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при добавлении позиций меню',
      }),
    );
  }

  deleteMenuItem(variables: MutationDeleteMenuItemArgs): MutationResult<'deleteMenuItem'> {
    return this.menuApi.deleteMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении позиции меню',
        success: 'Позиции меню успешно удалена',
      }),
    );
  }

  deleteMenuSection(variables: MutationDeleteMenuSectionArgs): MutationResult<'deleteMenuSection'> {
    return this.menuApi.deleteMenuSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении категории меню',
        success: 'Категория меню успешно удалена',
      }),
    );
  }

  updateMenuItem(variables: MutationUpdateMenuItemArgs): MutationResult<'updateMenuItem'> {
    return this.menuApi.updateMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении позиции меню',
      }),
    );
  }

  patchMenuSection(variables: MutationUpdateMenuSectionV2PatchArgs): MutationResult<'updateMenuSectionV2Patch'> {
    return this.menuApi.patchMenuSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении категории меню',
      }),
    );
  }

  changeMenuSectionPosition(variables: MutationChangeMenuSectionPositionArgs): MutationResult<'changeMenuSectionPosition'> {
    return this.menuApi.changeMenuSectionPosition(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении позиции категории меню',
      }),
    );
  }

  updateMenuSection(variables: MutationUpdateMenuSectionArgs): MutationResult<'updateMenuSection'> {
    return this.menuApi.updateMenuSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении категории меню',
      }),
    );
  }

  getMenuSection(variables: QueryMenuSectionArgs): QueryResult<'menuSection'> {
    return this.menuApi.getMenuSection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категории меню',
      }),
    );
  }

  getMenuItem(variables: QueryMenuItemArgs): QueryResult<'menuItem'> {
    return this.menuApi.getMenuItem(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении позиции меню',
      }),
    );
  }

  getMenuItemsBySection(variables: QueryAllMenuItemsBySectionArgs): QueryResult<'allMenuItemsBySection'> {
    return this.menuApi.getMenuItemsBySection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении позиций меню',
      }),
    );
  }

  getMenuSectionsBySection(variables: QueryMenuSectionsBySectionArgs): QueryResult<'menuSectionsBySection'> {
    return this.menuApi.getMenuSectionsBySection(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении категорий меню',
      }),
    );
  }

  updateMenuItemsList(variables: MutationUpdateMenuItemsListArgs): MutationResult<'updateMenuItemsList'> {
    return this.menuApi.updateMenuItemsList(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении позиций меню',
      }),
    );
  }

  updateMenuSectionsList(variables: MutationUpdateMenuSectionsListArgs): MutationResult<'updateMenuSectionsList'> {
    return this.menuApi.updateMenuSectionsList(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении категорий меню',
      }),
    );
  }

  onlineMenuSetActiveState(variables: MutationOnlineMenuSetActiveStateArgs): MutationResult<'onlineMenuSetActiveState'> {
    return this.menuApi.onlineMenuSetActiveState(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при включении/выключении онлайн-меню',
      }),
    );
  }

  onlineMenuSectionSetImage(variables: MutationOnlineMenuSectionSetImageArgs): MutationResult<'onlineMenuSectionSetImage'> {
    return this.menuApi.onlineMenuSectionSetImage(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении изображения категории онлайн-меню',
      }),
    );
  }

  getAllProductsPageable(variables: QueryAllCatalogProductsPageableArgs): QueryResult<'allCatalogProductsPageable'> {
    return this.productApi.getAllProductsPageable(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка товаров',
      }),
    );
  }
}
