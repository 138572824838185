import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { first, take } from 'rxjs/operators';

import { UserStorage } from '@app/modules/employees/services/employees/user.storage';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { ExportReportService } from '@app/shared/service/export-report.service';
import { phoneNumberValidator } from '@app/shared/validator/phoneNumberValidator';
import { Role } from '@app/typings/role';
import { InviteUserInput, UpdateUserInput, User } from '@app/typings/user';
import { DeleteEmployeeDialogComponent } from '@components/employee';
import { EXACT_CHARACTERS, exportUrlByReportType, MAX_CHARACTERS } from '@constants';
import { FormConfirmSaveService, UtilsService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { EmployeesStorage } from '@services/employee';
import { NotifyService, RedirectService, SidenavService } from '@services/shared';
import { EmployeeForm, EmployeeItemForm } from '@typings';
import { createExactLengthValidator, dateWithoutOffset } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  #roles = new BehaviorSubject<Role[]>([]);
  employee: User | null | undefined;
  form: FormGroup<EmployeeForm>;
  modalRef: ModalRef<unknown>;
  exportReportType = exportUrlByReportType;
  readonly allStores: string = 'allStores';
  updateUserInTable = new Subject<User>();
  removeUserFromTable = new Subject<string>();

  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);

  constructor(
    private employeesStorage: EmployeesStorage,
    private userStorage: UserStorage,
    private formConfirmSaveService: FormConfirmSaveService,
    private router: Router,
    private sessionStorage: SessionStorage,
    private notifyService: NotifyService,
    private validationErrorsService: ValidationErrorsService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private modalService: ModalService,
    private exportReportService: ExportReportService,
    private utilsService: UtilsService,
    private redirectService: RedirectService,
    private tableSidenavService: TableSidebarLayoutService,
  ) {}

  setRoles(roles: Role[]): void {
    this.#roles.next(roles);
  }

  initState(employee: User | null | undefined): void {
    this.employee = employee;
  }

  initForm(): void {
    this.form = this.fb.group<EmployeeForm>({
      lastName: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.LAST_NAME)]),
      firstName: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.FIRST_NAME)]),
      middleName: this.fb.control(null, [Validators.maxLength(MAX_CHARACTERS.MIDDLE_NAME)]),
      email: this.fb.nonNullable.control('', [Validators.required, Validators.email, Validators.maxLength(MAX_CHARACTERS.EMAIL)]),
      phone: this.fb.control(null, [phoneNumberValidator(), Validators.maxLength(MAX_CHARACTERS.PHONE)]),
      vatin: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.VATIN)]),
      items: this.fb.nonNullable.array(new Array<FormGroup<EmployeeItemForm>>(), [Validators.required]),
      active: this.fb.nonNullable.control(true, [Validators.required]),
      pin: this.fb.control(null),
      snils: this.fb.control(null, [createExactLengthValidator(EXACT_CHARACTERS.SNILS)]),
      dateOfBirth: this.fb.control(null),
      medicalBookExpiresAt: this.fb.control(null),
      dateOfEmployment: this.fb.control(null),
      address: this.fb.control(null),
    });

    if (this.employee) {
      const { controls } = this.form;
      const {
        lastName,
        firstName,
        middleName,
        workEmail,
        workPhone,
        vatin,
        pin,
        active,
        roles,
        snils,
        dateOfBirth,
        medicalBookExpiresAt,
        dateOfEmployment,
        address,
        inviteStatus,
      } = this.employee;

      controls.lastName.setValue(lastName);
      controls.firstName.setValue(firstName);

      if (middleName) {
        controls.middleName.setValue(middleName);
      }

      controls.email.setValue(workEmail);

      if (workPhone) {
        controls.phone.setValue(workPhone);
      }

      if (vatin) {
        controls.vatin.setValue(vatin);
      }

      if (roles && this.#roles.value.length) {
        this.#roles.value.forEach((userGroup) => controls.items.push(this.createEmployeeItem(userGroup)));
      }

      controls.active.setValue(active);

      if (pin) {
        controls.pin.setValue(inviteStatus !== 'IN_PROGRESS' ? pin : 'Выдается после подтверждения аккаунта');
      }

      if (snils) {
        controls.snils.setValue(snils);
      }

      if (dateOfBirth) {
        controls.dateOfBirth.setValue(dateOfBirth);
      }

      if (medicalBookExpiresAt) {
        controls.medicalBookExpiresAt.setValue(medicalBookExpiresAt);
      }

      if (dateOfEmployment) {
        controls.dateOfEmployment.setValue(dateOfEmployment);
      }

      if (address) {
        controls.address.setValue(address);
      }
    }

    this.isEditing$.next(!!this.employee);
    this.isSubmitDisabled$.next(false);
    this.formConfirmSaveService.setForm(this.form);
  }

  createEmployeeItem(employeeRole?: Role): FormGroup<EmployeeItemForm> {
    const formGroup: FormGroup<EmployeeItemForm> = this.fb.group<EmployeeItemForm>({
      // store: this.fb.nonNullable.control(null, [Validators.required]),
      role: this.fb.control(null, [Validators.required]),
    });

    if (employeeRole) {
      const { controls } = formGroup;
      const { name, id } = employeeRole;

      if (id) {
        controls.role.setValue({
          id: id,
          label: name || '',
          type: 'item',
        });
      }
    }

    return formGroup;
  }

  back(shouldConfirm: boolean = true) {
    this.#enableForm();
    this.sidenavService.back(shouldConfirm);
  }

  showDeleteModal(employee: User, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(DeleteEmployeeDialogComponent, { data: { employee, callbackFn } });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  submitForm(sidenavMode: boolean): void {
    if (this.form.invalid) {
      const itemsValue = [...this.form.controls.items.value];

      this.validationErrorsService.markFormControls(this.form);

      let notificationTitle = 'Необходимо добавить роль';

      if (this.form.value.items?.length) {
        notificationTitle = 'Необходимо заполнить обязательные поля';
      }

      if (this.router.url.includes('additional')) {
        notificationTitle = 'Необходимо заполнить обязательные поля на вкладке «Информация»';
      }

      this.notifyService.addNotification({
        type: 'alert',
        title: notificationTitle,
      });

      if (itemsValue && itemsValue.length) {
        const roles = itemsValue.map((it) => it.role);

        this.form.controls.items.controls.forEach((control, index) => control.controls.role.setValue(roles[index]));
      }

      return;
    }

    this.#disableForm();

    this.employee ? this.#updateEmployee(sidenavMode) : this.#createEmployee();
  }

  #disableForm(): void {
    this.form.disable({ emitEvent: false });
    this.isSubmitDisabled$.next(true);
  }

  #enableForm(): void {
    this.form.enable({ emitEvent: false });
    this.isSubmitDisabled$.next(false);
  }

  #createEmployee(): void {
    this.userStorage.inviteUser({ input: this.#getCreateEmployeeInput() }).subscribe(
      () => this.back(false),
      () => this.#enableForm(),
    );
  }

  #getCreateEmployeeInput(): InviteUserInput {
    const {
      lastName,
      firstName,
      middleName,
      email,
      phone,
      vatin,
      items,
      snils,
      dateOfBirth,
      medicalBookExpiresAt,
      dateOfEmployment,
      address,
    } = this.form.controls;
    const createEmployeeInput: InviteUserInput = {
      lastName: lastName.value,
      firstName: firstName.value,
      workEmail: email.value,
      roleIds: [],
    };

    if (middleName.value) {
      createEmployeeInput.middleName = middleName.value;
    }

    if (phone.value) {
      createEmployeeInput.workPhone = this.utilsService.setParsedPhone(phone.value);
    }

    if (vatin.value) {
      createEmployeeInput.vatin = vatin.value;
    }

    if (items.value && items.value.length) {
      createEmployeeInput.roleIds = items.value.map((it) => it.role!.id);
    }

    if (snils.value) {
      createEmployeeInput.snils = snils.value;
    }

    if (dateOfBirth.value) {
      createEmployeeInput.dateOfBirth = dateWithoutOffset(dateOfBirth.value);
    }

    if (medicalBookExpiresAt.value) {
      createEmployeeInput.medicalBookExpiresAt = dateWithoutOffset(medicalBookExpiresAt.value);
    }

    if (dateOfEmployment.value) {
      createEmployeeInput.dateOfEmployment = dateWithoutOffset(dateOfEmployment.value);
    }

    if (address.value) {
      createEmployeeInput.address = address.value;
    }

    return createEmployeeInput;
  }

  #updateEmployee(sidenavMode: boolean): void {
    const items = this.form.controls.items.value.map((it) => it.role!.id);
    const removeRole = this.#roles.value.filter((r) => !items.find((it) => it === r.id)).map((r) => r.id);
    const addRole = items.filter((it) => !this.#roles.value.find((r) => it === r.id));
    const requests = [];

    if (!!addRole.length) {
      requests.push(this.userStorage.addUserRoles({ id: this.employee!.id, roleIds: addRole }));
    }

    if (!!removeRole.length) {
      requests.push(this.userStorage.removeUserRoles({ id: this.employee!.id, roleIds: removeRole }));
    }

    if (!!requests.length) {
      combineLatest(requests)
        .pipe()
        .subscribe(() => {
          this.updateUser(sidenavMode);
        });
    } else {
      this.updateUser(sidenavMode);
    }
  }

  updateUser(sidenavMode: boolean) {
    this.userStorage.updateUser({ input: this.#getUpdateEmployeeInput() }).subscribe(
      (res) => {
        if (sidenavMode) {
          this.tableSidenavService.closeTableSidenav();
          this.updateUserInTable.next(res?.data?.updateUser?.updatedUser as User);
          this.formConfirmSaveService.closeForm(false);
        }

        this.back(false);
      },
      () => this.#enableForm(),
    );
  }

  #getUpdateEmployeeInput(): UpdateUserInput {
    const {
      lastName,
      firstName,
      middleName,
      email,
      phone,
      active,
      vatin,
      snils,
      dateOfBirth,
      medicalBookExpiresAt,
      dateOfEmployment,
      address,
    } = this.form.controls;
    const updateUserInput: UpdateUserInput = {
      id: this.employee!.id,
      lastName: lastName.value,
      firstName: firstName.value,
      workEmail: email.value,
      active: active.value,
    };

    if (middleName.value) {
      updateUserInput.middleName = middleName.value;
    }

    if (phone.value) {
      updateUserInput.workPhone = this.utilsService.setParsedPhone(phone.value);
    }

    if (vatin.value) {
      updateUserInput.vatin = vatin.value;
    }

    if (snils.value) {
      updateUserInput.snils = snils.value;
    }

    if (dateOfBirth.value) {
      updateUserInput.dateOfBirth = dateWithoutOffset(dateOfBirth.value);
    }

    if (medicalBookExpiresAt.value) {
      updateUserInput.medicalBookExpiresAt = dateWithoutOffset(medicalBookExpiresAt.value);
    }

    if (dateOfEmployment.value) {
      updateUserInput.dateOfEmployment = dateWithoutOffset(dateOfEmployment.value);
    }

    if (address.value) {
      updateUserInput.address = address.value;
    }

    return updateUserInput;
  }

  deleteEmployee(employee: User, callbackFn: () => void): void {
    if (!employee || !employee.id) {
      return;
    }

    this.modalRef.close();
    this.userStorage.deleteUser({ id: employee.id }).pipe(first()).subscribe(callbackFn);
  }

  exportReportAll(): void {
    const exportParams: Record<string, string> = {
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    this.exportReportService.exportReportWithHandler(
      this.exportReportType.EMPLOYEE.url,
      exportParams,
      this.exportReportType.EMPLOYEE.fileName,
    );
  }

  updateEmployeePinCode(): void {
    if (this.employee && this.employee.id) {
      this.userStorage
        .changeUserPin({ userId: this.employee?.id })
        .pipe(map((res) => res.data?.refreshUserPin.pin))
        .subscribe((pin) => this.form.controls.pin.setValue(pin || ''));
    }
  }

  openEmployeesPage(id?: string): void {
    this.formConfirmSaveService.closeForm(false);

    this.redirectService.openEmployeesPage(this.sessionStorage.getOrgId(), id);
  }

  showExitModal() {
    this.formConfirmSaveService
      .closeForms(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidenavService.closeTableSidenav();
          this.formConfirmSaveService.forms = [];
        }
      });
  }
}
