import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mutationUploadImages, queryImageById, queryImagesWithSize } from '@api';
import { ApolloQueryResult } from '@apollo/client/core';
import { FileError } from '@app/shared/component/input-file/input-file.component';
import { CoreSchema, Image, ImageInput, Query } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  files: File[] | [SafeUrl | string, string][] | null[] = [];
  hints: string[] = [];
  isErrors: boolean[] = [];
  controls: FormArray<FormControl<ImageInput | null>> = this.fb.array<FormControl<ImageInput | null>>([]);

  constructor(private apollo: Apollo, private fb: FormBuilder) {}

  getImagesWithSize(images: CoreSchema.ImagesSizeInput[]): Observable<ApolloQueryResult<{ imagesWithSize: CoreSchema.AllImageWithSizes }>> {
    return this.apollo.watchQuery<{ imagesWithSize: CoreSchema.AllImageWithSizes }>({
      query: queryImagesWithSize,
      variables: {
        images,
      },
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  uploadImages(images: ImageInput[]): Promise<string> {
    return lastValueFrom(
      this.apollo
        .query<{ uploadImages: CoreSchema.UploadImageResponse }>({
          query: mutationUploadImages,
          variables: { images },
          context: { useMultipart: true },
          fetchPolicy: 'no-cache',
        })
        .pipe(map((res) => res.data.uploadImages.mutationResultList[0].entityId)),
    );
  }

  createImageInput(file: File): ImageInput {
    return {
      body: file,
      extension: file.name.split('.').pop(),
      originalFileName: file.name.split('.').shift(),
    };
  }

  setFile(file: File | [SafeUrl | string, string] | null, index: number = 0) {
    this.files[index] = file;
  }

  deleteFile(index: number = 0) {
    this.files.splice(index, 1);
  }

  setFileControl(
    file: File,
    control: FormControl<ImageInput | null>,
    index: number = 0,
    array?: FormArray<FormControl<ImageInput | null>>,
  ): void {
    this.files[index + 1] = file;
    control.setValue(file ? this.createImageInput(file) : null);
    this.setHint('', index);
    this.setIsError(false, index);
    if (file && array) {
      array.insert(0, new FormControl<ImageInput | Image | null>(null));
    }
  }

  removeFileControl(array: FormArray<FormControl<ImageInput | null>>, index: number) {
    array.removeAt(index);
  }

  setHint(hint: string, index: number): void {
    this.hints[index] = hint;
  }

  setIsError(isError: boolean, index: number): void {
    this.isErrors[index] = isError;
  }

  resetImage() {
    this.hints.forEach((_, index) => this.setHint('', index));
    this.isErrors.forEach((_, index) => this.setIsError(false, index));
  }

  fileValidationError(fileError: FileError, index: number = 0): void {
    if (fileError.reason === 'fileTypeNotAllowed') {
      this.setHint(`Разрешенные типы файлов ${fileError.allowed.join(', ')}`, index);
      this.setIsError(true, index);
    }

    if (fileError.reason === 'fileLimitExceeded') {
      this.setHint(`Превышен максимально допустимый вес файла`, index);
      this.setIsError(true, index);
    }
  }

  imageById(variables: CoreSchema.QueryImageByIdArgs): Observable<Image> {
    return this.apollo
      .query<Query<'imageById'>, CoreSchema.QueryImageByIdArgs>({
        query: queryImageById,
        variables,
      })
      .pipe(map((res) => res.data.imageById!));
  }

  imageByIdPromise(variables: CoreSchema.QueryImageByIdArgs): Promise<Image> {
    return firstValueFrom(
      this.apollo
        .query<Query<'imageById'>, CoreSchema.QueryImageByIdArgs>({
          query: queryImageById,
          variables,
        })
        .pipe(map((res) => res.data.imageById!)),
    );
  }
}
