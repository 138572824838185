<div [ngClass]="inputImageClasses" [attr.data-test-id]="testId">
  <img
    #image
    *ngIf="imageSrc"
    [ngStyle]="{ zIndex: previewLoading ? -1 : 'auto' }"
    class="image"
    [src]="imageSrc"
    [alt]="fileName"
    (load)="handleLoadFinished()"
    (error)="handleLoadError($event)"
  />

  <div *ngIf="loading || previewLoading" class="spinner">
    <nm-progress-spinner mode="indeterminate" [diameter]="18"></nm-progress-spinner>
  </div>

  <nm-svg-icon *ngIf="!fileUrl && !loading" [size]="24" name="upload"></nm-svg-icon>
  <nm-svg-icon *ngIf="iconSrc" class="file-icon" [size]="24" [name]="iconSrc"></nm-svg-icon>

  <span *ngIf="!imageSrc" class="label" [title]="label || defaultLabel">{{ label || defaultLabel }}</span>
  <span *ngIf="isCover" class="cover">Обложка</span>

  <div *ngIf="showControl" [ngClass]="controlIconClasses" (click)="handleRemove($event)">
    <nm-svg-icon [size]="24" [name]="loading ? 'close' : 'delete'"></nm-svg-icon>
  </div>
</div>
