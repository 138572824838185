import { AbcRecommendationTypeStrict, AbcRecommendationTypeString, AbcReportV3SortFieldStrict } from '@typings';

export const ABC_REPORT_SORT_FIELD: AbcReportV3SortFieldStrict = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  PROFIT: 'PROFIT',
  PROFIT_GROUP: 'PROFIT_GROUP',
  PROFIT_PERCENT: 'PROFIT_PERCENT',
  REVENUE: 'REVENUE',
  REVENUE_GROUP: 'REVENUE_GROUP',
  REVENUE_PERCENT: 'REVENUE_PERCENT',
  SECTION_NAME: 'SECTION_NAME',
  UNIT_TYPE: 'UNIT_TYPE',
  ITEMS_COUNT: 'ITEMS_COUNT',
  ITEMS_COUNT_GROUP: 'ITEMS_COUNT_GROUP',
  ITEMS_COUNT_PERCENT: 'ITEMS_COUNT_PERCENT',
  ORDERS_COUNT: 'ORDERS_COUNT',
  ORDERS_COUNT_GROUP: 'ORDERS_COUNT_GROUP',
  ORDERS_COUNT_PERCENT: 'ORDERS_COUNT_PERCENT',
  FOOD_COST: 'FOOD_COST',
  FOOD_COST_GROUP: 'FOOD_COST_GROUP',
  FOOD_COST_OF_REVENUE_PERCENT: 'FOOD_COST_OF_REVENUE_PERCENT',
  FOOD_COST_PERCENT: 'FOOD_COST_PERCENT',
};

export const ABC_RECOM_TYPE: AbcRecommendationTypeStrict = {
  CONTROVERSIAL: 'CONTROVERSIAL',
  GO_LIST: 'GO_LIST',
  NOT_RECOMMENDED: 'NOT_RECOMMENDED',
  TO_REMOVE: 'TO_REMOVE',
};

export const ABC_RECOM_TYPE_STRING: AbcRecommendationTypeString = {
  CONTROVERSIAL: 'Принять решение необходимо самостоятельно',
  GO_LIST: 'Рекомендуем поставить позицию в Go-list',
  NOT_RECOMMENDED: 'Не рекомендуем ставить в Go-list',
  TO_REMOVE: 'Рекомендуем убрать из меню',
};
