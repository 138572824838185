import { Injectable } from '@angular/core';

import { NotifyService } from '@services/shared';

import { ProductApi } from '../product/product.api';
import { ProductStorage } from '../product/product.storage';

@Injectable({
  providedIn: 'root',
})
export class ProductModifierStorage extends ProductStorage {
  constructor(protected notifyService: NotifyService, protected productApi: ProductApi) {
    super(notifyService, productApi);
    this.productType = 'MODIFIER';
  }
}
