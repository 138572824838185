<nm-modal-base
  (cancel)="cancel()"
  (ok)="confirm()"
  [okTitle]="btnTitle"
  okVariant="filled"
  [cancelTitle]="'Отменить'"
  [okLoading]="menuService.isLoading$ | async"
  [okDisabled]="menuService.isLoading$ | async"
  testId="menu::modal-edit"
  *ngrxLet="{ storeOptions: searchStoresService.options$ } as obs"
>
  <div modal-base-title>{{ title }}</div>
  <ng-container *ngIf="menuService.form as form">
    <nm-form class="form" [form]="form">
      <nm-input
        label="Название меню"
        placeholder="Введите название"
        ngDefaultControl
        [required]="true"
        [formControl]="name"
        [maxLength]="MAX_CHARACTERS.PRODUCT_NAME"
        [error]="validationErrorsService.isControlDirtyAndInvalid(name)"
        [hint]="validationErrorsService.validateField(name) || name.getError('name')"
        testId="name"
      ></nm-input>

      <nm-autocomplete
        #autocompleteStores
        testId="menu::stores"
        ngDefaultControl
        label="Точки продаж"
        placeholder="Выберите точки продаж"
        [isMulti]="true"
        [returnObjects]="true"
        [search]="true"
        [formControl]="stores"
        [showClear]="false"
        [searchFn]="searchStoresService.searchFn"
        [options]="searchStoresService.options$"
        [allSelected]="menuService.allSelectedStores$ | async"
        [selectAllSettings]="{ title: 'Все точки продаж' }"
        [error]="validationErrorsService.isControlDirtyAndInvalid(stores)"
        [hint]="controlErrorHint(stores, 'stores')"
        (selectAllHandler)="menuService.setAllSelectedStores($event.selected)"
        (deleteChip)="deleteStoreChip($event, obs.storeOptions)"
      ></nm-autocomplete>

      <ng-container *ngIf="!menu">
        <nm-autocomplete
          #autocompleteCatalog
          label="Позиции"
          testId="menu-create::categories-and-products"
          placeholder="Добавьте позиции"
          ngDefaultControl
          [isMulti]="true"
          [returnObjects]="true"
          [search]="true"
          [formControl]="structure"
          [error]="validationErrorsService.isControlDirtyAndInvalid(structure)"
          [hint]="validationErrorsService.validateField(structure) || structure.getError('structure')"
          [searchFn]="autocompleteTreeService.searchFn"
          [getSelectedOptionFn]="autocompleteTreeService.getSelectedOptionFn"
          [options]="autocompleteTreeService.options$"
          [multiSelectTreeUpdateSelected]="autocompleteTreeService.updateSelected | async"
          [allSelected]="menuService.allCatalogSelected$ | async"
          [selectAllSettings]="{
            title: 'Все категории и позиции'
          }"
          [autocompleteTreeService]="autocompleteTreeService"
          collapseChipsLabel="PRODUCTS_AND_CATEGORIES"
          (selectAllHandler)="menuService.setAllCatalogSelected($event.selected)"
        ></nm-autocomplete>
        <div class="checkbox">
          <nm-checkbox (checkboxChange)="change($event)"></nm-checkbox> Импортировать товары из каталога вместе со
          структурой категорий
        </div>
      </ng-container>
    </nm-form>
  </ng-container>
</nm-modal-base>
