import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WarehouseService, WarehouseStorage } from '@app/modules/warehouse/services';
import { AutocompleteComponent } from '@app/shared/component/autocomplete/autocomplete.component';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { InfiniteLoaderAutocompleteFactory } from '@app/shared/service/autocomplete/infinite-loader-autocomplete.service';
import { InfiniteLoadResult } from '@app/shared/service/infinite-loader/infinite-loader.service';
import { ValidationErrorsService } from '@services/core';
import { StoresService } from '@services/settings';
import { FunctionTypeVoid, PageableInput, StorageRoom } from '@typings';

@Component({
  selector: 'nm-warehouse-archive-dialog',
  templateUrl: './warehouse-archive-dialog.component.html',
  styleUrls: ['./warehouse-archive-dialog.component.scss'],
})
export class WarehouseArchiveDialogComponent {
  message: string;

  get form() {
    return this.warehouseService.archiveForm;
  }

  get hasStores(): boolean {
    return this.warehouseService.hasStores(this.data.warehouse);
  }

  get newWarehouse() {
    return this.form.controls.newWarehouse;
  }

  #searchWarehousesFn = (
    pageRequest: PageableInput,
    searchText?: string,
  ): Observable<InfiniteLoadResult<AutocompleteOption<StorageRoom>>> => {
    return this.warehouseStorage
      .storageRooms({
        pageRequest,
        filter: {
          search: searchText,
          states: ['NOT_ARCHIVED'],
        },
      })
      .pipe(
        map((res) => {
          const { content, total, totalPages } = res.data.storageRooms;
          const items: AutocompleteOption<StorageRoom>[] = content
            .filter((s): s is StorageRoom & { id: string; name: string } => !!s.id && !!s.name)
            .map((item) => ({
              id: item.id,
              type: 'item',
              label: item.name,
              disabled: item.id === this.data.warehouse.id,
              data: item,
            }));
          return { items, total, totalPages };
        }),
      );
  };

  searchWarehousesService = InfiniteLoaderAutocompleteFactory.getService<StorageRoom>(this.#searchWarehousesFn);

  constructor(
    public warehouseService: WarehouseService,
    public storesService: StoresService,
    private warehouseStorage: WarehouseStorage,
    public validationErrorsService: ValidationErrorsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      warehouse: StorageRoom;
      fromListCallback?: FunctionTypeVoid;
    },
  ) {
    this.message = this.hasStores
      ? `Необходимо выбрать склад, к которому будут привязаны точки продаж после архивации «${this.data.warehouse?.name}». В этом случае доступ к работе с остатками и складскими документами на выбранном складе будет недоступен. Вы можете возобновить работу со складскими операциями после того как разархивируете склад.`
      : `После архивации склада «${this.data.warehouse?.name}», доступ к работе с остатками и складскими документами на выбранном складе будет недоступен. Вы можете возобновить работу со складскими операциями после того как разархивируете склад.`;
    if (this.hasStores) {
      this.warehouseService.initArchiveForm();
    }
  }

  archive() {
    this.warehouseService.archiveWarehouse(this.data.warehouse, this.data.fromListCallback);
  }

  onOpenWarehouseAutocomplete(autocompleteComponent: AutocompleteComponent) {
    autocompleteComponent.searchFn?.('');
  }
}
