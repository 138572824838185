import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { ModalBaseComponent } from '@app/shared/component/dialog/modal-base/modal-base.component';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { ImagesService } from '@app/shared/service/images.service';
import { Workshop } from '@app/typings/workshop';
import { catalogSectionToAutocompleteOption } from '@app/utils/catalog-section';
import { CatalogRoute, MAX_CHARACTERS, rootCatalogSection, RootNavigationRoute, ROUTE_CREATE_NEW } from '@constants';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { NotifyService } from '@services/shared';
import {
  Catalog,
  CatalogSection,
  CatalogSectionCreateInputV2,
  CatalogSectionUpdateInputV2,
  CategoryForm,
  InCatalogFilterInput,
  InCatalogPage,
  PageRequestInput,
} from '@typings';

import { CatalogApi } from '../catalog/catalog.api';
import { CatalogStorage } from '../catalog/catalog.storage';

import { CategoriesStorage } from './categories.storage';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  form: FormGroup<CategoryForm>;
  controls: CategoryForm;
  catalog: Catalog;
  category: CatalogSection;
  modalRef: ModalRef<ModalBaseComponent>;
  parentCategory: AutocompleteOption<CatalogSection> | null;
  sidenavMode = false;
  updateCategoryInTable = new Subject<CatalogSection>();
  removeCategoryFromTable = new Subject<string>();
  isEditing$ = new BehaviorSubject(false);
  isSubmitDisabled$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sessionStorage: SessionStorage,
    private catalogStorage: CatalogStorage,
    private categoriesStorage: CategoriesStorage,
    private formConfirmSaveService: FormConfirmSaveService,
    private modalService: ModalService,
    private imagesService: ImagesService,
    private catalogApi: CatalogApi,
    private validationErrorsService: ValidationErrorsService,
    private notifyService: NotifyService,
    private tableSidebarService: TableSidebarLayoutService,
  ) {}

  openCategoryPage(id?: string, category?: CatalogSection): Promise<boolean> {
    if (category) {
      this.parentCategory = catalogSectionToAutocompleteOption(category) as AutocompleteOption<CatalogSection>;
    }

    return this.router.navigate([
      this.sessionStorage.getOrgId(),
      RootNavigationRoute.catalog,
      CatalogRoute.categories,
      id || ROUTE_CREATE_NEW,
    ]);
  }

  initState(category: CatalogSection): void {
    this.catalog = this.catalogStorage.getCatalog();
    this.category = category;
  }

  initForm(): void {
    this.form = this.fb.group<CategoryForm>({
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.NAME)]),
      section: this.fb.control(this.parentCategory ? this.parentCategory : null, [Validators.required]),
      workshop: this.fb.control(null),
      image: this.fb.control(null),
      positionTypeCode: this.fb.control(null, [Validators.required]),
      sno: this.fb.control(null, [Validators.required]),
      vat: this.fb.control(null, [Validators.required]),
    });
    this.controls = this.form.controls;

    if (this.catalog) {
      const { positionType, snoValue, vatValue } = this.catalog;
      if (!this.parentCategory) {
        this.controls.section.setValue(catalogSectionToAutocompleteOption(rootCatalogSection) as AutocompleteOption<CatalogSection>);
      }

      if (positionType && positionType.code) {
        this.controls.positionTypeCode.setValue(positionType.code);
      }

      if (snoValue && snoValue.sno) {
        this.controls.sno.setValue(snoValue.sno);
      }

      if (vatValue && vatValue.vat) {
        this.controls.vat.setValue(vatValue.vat);
      }
    }

    if (this.category) {
      const { name, parent, workshop, image, positionType, snoValue, vatValue, colorSection } = this.category;

      this.controls.name.setValue(name);

      if (workshop) {
        const option: AutocompleteOption<Workshop> = {
          id: workshop.id,
          label: workshop.name,
          type: 'item',
          data: workshop,
        };
        this.controls.workshop.setValue(option);
      }

      if (parent) {
        this.controls.section.setValue(catalogSectionToAutocompleteOption(parent) as AutocompleteOption<CatalogSection>);
      }

      if (image && image.imageSizes.length) {
        this.controls.image.setValue(image);
      }

      if (positionType && positionType.code) {
        this.controls.positionTypeCode.setValue(positionType.code);
      }

      if (snoValue && snoValue.sno) {
        this.controls.sno.setValue(snoValue.sno);
      }

      if (vatValue && vatValue.vat) {
        this.controls.vat.setValue(vatValue.vat);
      }

      if (colorSection) {
        this.form.addControl('colorSection', this.fb.nonNullable.control(colorSection, [Validators.required]));
      }
    }

    this.isSubmitDisabled$.next(false);
    this.isEditing$.next(!!this.category);

    this.formConfirmSaveService.setForm(this.form);
  }

  resetPositionTypeCode(): void {
    if (!this.category && this.catalog.positionType && this.catalog.positionType.code) {
      this.controls.positionTypeCode.setValue(this.catalog.positionType.code);
    }

    if (this.category && this.category.positionType && this.category.positionType.code) {
      this.controls.positionTypeCode.setValue(this.category.positionType.code);
    }
  }

  resetSno(): void {
    if (!this.category && this.catalog.snoValue && this.catalog.snoValue.sno) {
      this.controls.sno.setValue(this.catalog.snoValue.sno);
    }

    if (this.category && this.category.snoValue && this.category.snoValue.sno) {
      this.controls.sno.setValue(this.category.snoValue.sno);
    }
  }

  resetVat(): void {
    if (!this.category && this.catalog.vatValue && this.catalog.vatValue.vat) {
      this.controls.vat.setValue(this.catalog.vatValue.vat);
    }

    if (this.category && this.category.vatValue && this.category.vatValue.vat) {
      this.controls.vat.setValue(this.category.vatValue.vat);
    }
  }

  resetSnoAndVat(): void {
    this.resetSno();
    this.resetVat();
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);

      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });
      return;
    }
    this.parentCategory = null;

    this.disableForm();

    this.category ? this.updateCategory() : this.createCategory();
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  async createCategory(): Promise<void> {
    const catalogSection = await this.createCatalogSectionCreateInput();
    const image = this.controls.image.value;

    if (image && 'extension' in image) {
      catalogSection.imageId = await this.imagesService.uploadImages([image]);
    }

    this.categoriesStorage.createCategory({ catalogSection }).subscribe(
      () => this.toList(),
      () => this.enableForm(),
    );
  }

  createCatalogSectionCreateInput(): CatalogSectionCreateInputV2 {
    const { name, section, workshop, positionTypeCode, sno, vat } = this.controls;
    const catalogSectionCreateInput: CatalogSectionCreateInputV2 = {
      catalogId: this.catalog.id,
      name: name.value,
    };

    if (workshop.value) {
      catalogSectionCreateInput.workshopId = workshop.value?.id;
    }

    const parentSection = section.value;

    if (!parentSection || parentSection.id === 'root') {
      if (this.catalog.positionType?.code !== positionTypeCode.value) {
        catalogSectionCreateInput.positionTypeCode = positionTypeCode.value;
      }

      if (this.catalog.snoValue?.sno !== sno.value || this.catalog.vatValue?.vat !== vat.value) {
        catalogSectionCreateInput.taxProfile = {
          sno: sno.value,
          vat: vat.value,
        };
      }
    }

    if (parentSection && parentSection.id !== 'root') {
      catalogSectionCreateInput.parentSectionId = parentSection.id;

      if (parentSection.data?.positionType?.code !== positionTypeCode.value) {
        catalogSectionCreateInput.positionTypeCode = positionTypeCode.value;
      }

      if (parentSection.data?.snoValue?.sno !== sno.value || parentSection.data?.vatValue?.vat !== vat.value) {
        catalogSectionCreateInput.taxProfile = {
          sno: sno.value,
          vat: vat.value,
        };
      }
    }

    return catalogSectionCreateInput;
  }

  async updateCategory(): Promise<void> {
    const catalogSection = this.createCatalogSectionUpdateInput();
    const image = this.controls.image;

    if (image) {
      const imageValue = image.value;

      if (!imageValue) {
        catalogSection.imageId = null;
      }

      if (imageValue && 'imageSizes' in imageValue) {
        catalogSection.imageId = imageValue.id;
      }

      if (imageValue && 'extension' in imageValue) {
        catalogSection.imageId = await this.imagesService.uploadImages([image.value]);
      }
    }

    this.categoriesStorage.updateCategory({ catalogSection }).subscribe(
      (res) => {
        if (!this.sidenavMode) {
          this.toList();
        } else {
          this.tableSidebarService.closeTableSidenav();
          this.updateCategoryInTable.next(res?.data?.updateCatalogSectionV2?.output! as CatalogSection);
        }
        this.toList();
      },
      () => this.enableForm(),
    );
  }

  createCatalogSectionUpdateInput(): CatalogSectionUpdateInputV2 {
    return { ...this.createCatalogSectionCreateInput(), id: this.category.id, colorSection: this.controls.colorSection!.value };
  }

  deleteCategory(): void {
    this.categoriesStorage.deleteCategory({ id: this.category.id }).subscribe(
      () => {
        this.modalRef.close();
        if (!this.sidenavMode) {
          this.toList();
        }
        if (this.sidenavMode) {
          this.removeCategoryFromTable.next(this.category?.id!);
          this.tableSidebarService.closeTableSidenav();
        }
        this.toList();
      },
      () => this.enableForm(),
    );
  }

  getCategories(filter: InCatalogFilterInput, pageRequest?: PageRequestInput): Observable<InCatalogPage> {
    return this.catalogApi
      .getAllInCatalogPageable({
        filter,
        pageRequest,
      })
      .pipe(map((res) => res.data.allInCatalogPageable));
  }

  getAllSections(): Observable<CatalogSection[]> {
    return this.categoriesStorage.getSectionsList({ pageRequest: { page: 0, size: 1000 } }).pipe(map((res) => res.data.sections.content));
  }
  showExitModal() {
    this.formConfirmSaveService
      .closeForm(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidebarService.closeTableSidenav();
        }
      });
  }
  changeSidenavMode(state: boolean) {
    this.sidenavMode = state;
  }
  getSectionsByIds(sectionIds: string[]): Observable<CatalogSection[]> {
    return this.categoriesStorage
      .getSectionsByIds({
        pageRequest: { page: 0, size: sectionIds.length },
        sectionIds: sectionIds,
      })
      .pipe(map((res) => res.data.sectionsByIds.content));
  }

  toList(shouldConfirm: boolean = false): void {
    this.formConfirmSaveService
      .closeForm(shouldConfirm)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.router.navigateByUrl(
            this.router.url.replace(`categories/${this.category ? this.category.id : ROUTE_CREATE_NEW}`, 'categories'),
          );
        }
      });
  }

  back(): void {
    if (this.sidenavMode) {
      this.showExitModal();
    } else {
      this.toList(true);
    }
  }

  showModal(modalTemplate: TemplateRef<ModalBaseComponent>): void {
    this.modalRef = this.modalService.openDialog(modalTemplate);
  }

  hideModal(): void {
    this.modalRef.close();
  }
}
